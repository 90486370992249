@import '_mixins';

.timer__date-note {
  display: none;
}

/* Tablet */
@include media(tablet) {
  .main {
    ::before, ::after {
      content: none;
    }
    height: 700px;

    .container {
      width: auto;
    }

    .top-line {
      display: flex;
      justify-content: space-between;
      padding: 5px;
    }
  }

  .timer__date-note {
    display: block;
  }

  .logo-wrapper,
  .header-menu {
    width: auto;
    float: none;
  }

  .logo-wrapper {
    margin: 0;
    margin-right: 10px;
  }

  .logo-desktop {
    height: auto;
    max-width: 180px;
  }

  .header-menu {
    text-align: start;

    .top {
      margin: 0;
      display: flex;
      align-items: center;
    }

    .header-callback-btn {
      font-size: 14px;
      padding: 4px 12px;
      margin-left: 20px;
    }
  }

  .header-menu__phone {
    display: none;
  }

  .header-menu__phone_mobile {
    display: block;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
    text-decoration: none;
  }

  .header__content {
    display: flex;
    justify-content: center;
    height: auto;
    width: auto;
    padding: 50px 5px 0;
  }

  .promo-note {
    text-align: center;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 10px;
    margin-top: 0;
    max-width: 350px;

    .counter {
      padding-bottom: 16px;
      width: 100%;
    }

    .info__title {
      width: 100%;
      font-size: 46px;
      line-height: 34px;
      padding-bottom: 10px;
    }
    .info__subtitle {
      width: 100%;
      font-size: 26px;
      line-height: 30px;
      margin-top: 10px;
      padding-bottom: 20px;

      span {
        color: #fff;
        font-size: inherit;
        display: inline-block;
        margin-top: 0;
      }
    }

    .numbers {
      margin: 0;
      display: flex;
      justify-content: center;
    }

    .number1, .number2, .number3 {
      height: auto;
      font-size: 50px;
      margin: 0;
      line-height: initial;
      float: none;

      @include not-last-child() {
        margin-right: 25px;
      }
    }

    .number1, .number2 {
      width: 70px;
    }

    .number3 {
      width: 100px;
    }

    .text {
      display: flex;
      margin-bottom: 0;
    }

    .text1, .text2, .text3 {
      margin: 0;
      font-size: 11px;
    }

    .text1 {
      width: 75px;
      margin-left: 15px;
    }

    .text2 {
      margin-left: 26px;
      width: 94px;
    }

    .text3 {
      margin-left: 5px;
    }

    .info__footer {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        margin: 0;
      }

      .note-symbol {
        display: none;
      }
    }
  }
}

/* Mobile */
@include media(mobile) {
  .main {
    background-position-x: 25%;
    background-size: 285%;
  }
  .header-callback-btn {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  .info__title {
    font-size: 46px !important;
  }
}