@import '_mixins';

/* Tablet */
@include media(tablet) {
  .advantages-two {
    @include clear-pseudo();
    padding: 25px 10px;

    .container {
      width: auto;
    }
  }
  .advantages__title_mobile {
    display: block;
    margin: 0;
    font-size: 28px;
    color: #fff;
    text-align: center;
    line-height: 32px;
    padding-bottom: 20px;
    text-transform: uppercase;

    span {
      font-weight: normal;
      text-transform: lowercase;
    }
  }
  .advanatges-two__list {
    flex-direction: column;
    justify-content: center;
    align-items: center
  }
  .advanatges-two__row {
    flex: 1;
    display: flex;
    justify-content: center;

    @include not-last-child() {
      padding-bottom: 10px;
    }
  }
  .advantages-two__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    margin: 0;

    @include not-last-child() {
      padding-right: 10px;
    }
  }
  .advanatges-two__info {
    margin: 0;
    text-align: center;
    padding: 10px 0;
  }
  .advantages-two__title {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 5px;
  }
  .advantages-two__description {
    line-height: 14px;
    font-size: 10px;
  }
}

/* Mobile */
@include media(mobile) {

}
