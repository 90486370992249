@import '_mixins';

/* Tablet */
@include media(tablet) {
  .mfp-wrap {
    top: 0 !important;
    position: fixed !important;
    z-index: 99999;
    overflow: auto;
  }
  .mfp-content {
    max-width: 400px;
    margin: 10px auto;

    .call {
      width: auto;
      padding: 30px 0;

      .call__title {
        font-size: 18px;
        border-bottom: 1px solid #ccc;
        margin: 0;
        padding: 0 55px;
        padding-bottom: 15px;
      }
      .call__description {
        margin: 0;
        padding: 10px;
        padding-bottom: 30px;
        font-size: 16px;
      }
      .call__control {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .call__label {
        font-size: 14px;

        &::after {
          content: none;
        }
      }

      .mfp-close {
        border: 1px solid #333;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        margin-top: 10px;
        width: 30px;
        height: 30px;
        right: 0;
        top: 0;
      }
    }
  }
}

/* Mobile */
@include media(mobile) {

}
