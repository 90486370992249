@import '_mixins';

/* Tablet */
@include media(tablet) {
  .low-price {
    padding: 25px 5px;

    .container {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .low-price__title {
    padding-right: 10px;
    font-size: 26px;
    line-height: 30px;
  }
  .low-price__title_mobile {
    text-transform: lowercase;
    font-weight: normal;
  }
  .low-price__title-description {
    display: none;
  }
  .low-price__button {
    text-transform: initial;
  }
}

/* Mobile */
@include media(mobile) {
  .low-price__title {
    padding-right: 0px;
    padding-bottom: 10px;
  }
}
