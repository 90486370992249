.slideout-menu {
  display: none;
}

@media screen and (max-width: 1024px) {
  /* Slideout */
  .slideout-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 300px;
    box-sizing: border-box;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    display: none;
  }
  .slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
  }
  .slideout-open,
  .slideout-open body,
  .slideout-open .slideout-panel {
    /* overflow: hidden; */
  }
  .slideout-open .slideout-menu {
    display: block;
    background: #333;
    z-index: 2000;
    box-shadow: 1px 1px 4px 1px #fff;
  }
  .slideout-open .slideout-panel {
    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0,0.6);
      z-index: 99999;
    }
  }


  /* Mobile menu */
  .mobile-menu {
    padding: 20px;
    font-family: 'Open Sans',sans-serif;
    display: none;
  }
  .mobile-menu__nav {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .mobile-menu__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
  .mobile-menu__logo {
    height: 40px;
  }
  .mobile-menu__btn_open {
    display: block !important;
    position: relative;
    width: 15px;
    height: 15px;
    padding: 10px;
    margin-left: 10px;
    background-image: url(../img/menu.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    top: 5px;
    right: 10px;
  }
  .mobile-menu__btn_close {
    display: block;
    width: 15px;
    height: 15px;
    padding: 10px;
    background-image: url(../img/close-mobile.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .mobile-menu__nav-item {
    display: block;
    padding: 5px;
    text-decoration: none;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  .mobile-menu__footer {
    display: flex;
    justify-content: center;
    padding: 20px;
    flex-direction: column;
    align-items: center;
  }
  .mobile-menu__footer .btn {
    font-size: 16px;
    font-weight: 400;
    background: #00c01b;
    padding: 8px 24px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    outline: 0;
    color: #fff;
    text-decoration: none;
  }
  .mobile-menu__phone {
    color: #fff;
    padding-bottom: 10px;
  }
  .mobile-menu__footer .header-menu .btn:hover {
    background: #fff;
    color: #00c01b;
  }
}