@import "mobile";

.action,
.form-wrapper,
.header-menu span,
body {
  position: relative;
}
.header-menu .btn,
.info a,
.list__item a,
.nav-list li a {
  text-decoration: none;
}
.action .container:after,
.advantages__list:after,
.bottom:after,
.hidden-menu .container:after,
.input-wrapper:after,
.list:after,
.numbers:after,
.text:after,
.top-line:after {
  clear: both;
}
@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/RobotoRegular/RobotoRegular.eot?#iefix) format("embedded-opentype"),
       url(../fonts/RobotoRegular/RobotoRegular.woff) format("woff"),
       url(../fonts/RobotoRegular/RobotoRegular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/RobotoBold/RobotoBold.eot?#iefix) format("embedded-opentype"),
       url(../fonts/RobotoBold/RobotoBold.woff) format("woff"),
       url(../fonts/RobotoBold/RobotoBold.ttf) format("truetype");
  font-display: swap;
}
::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}
:-moz-placeholder {
  color: #666;
  opacity: 1;
}
::-moz-placeholder {
  color: #666;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}
body input:focus:required:invalid,
body input:required:valid,
body textarea:focus:required:invalid,
body textarea:required:valid {
  color: #666;
}
body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  font-size: 16px;
  min-width: 320px;
  line-height: 1.75;
  font-family: roboto, sans-serif;
  overflow-x: hidden;
}
.header-menu .btn,
.header-menu span {
  font-family: "Open Sans", sans-serif;
  color: #fff;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.header-menu {
  width: 715px;
  float: left;
  text-align: right;
}
.header-menu span {
  font-size: 34px;
  line-height: 36px;
  font-weight: 700;
  top: 6px;
}
.header-menu .btn {
  font-size: 16px;
  font-weight: 400;
  background: #00c01b;
  padding: 8px 24px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin-left: 30px;
  outline: 0;
}
.header-menu .btn:hover {
  background: #fff;
  color: #00c01b;
}
.logo-wrapper {
  margin-top: 15px;
}
.top-line:after,
.top-line:before {
  content: "";
  display: table;
}
.top {
  margin-top: 8px;
}
.list {
  background: #fff;
  list-style: none;
  padding: 0;
  width: 683px;
  float: right;
  margin: 18px 0 0;
}
.list__item {
  width: 170px;
  border-right: 1px solid #c4c4c4;
  float: left;
  text-align: center;
  font-size: 15px;
  color: #5a5a5a;
  padding: 0;
}
.list__item:last-child {
  border-right: none;
}
.list__item:hover {
  background: #2b2a29;
}
.list__item:hover a {
  color: #fff;
}
.list__item a {
  color: #5a5a5a;
  width: 171px;
  display: block;
  padding: 6px 0;
}
.info,
.info h1,
.info h2 {
  color: #fff;
  text-align: left;
}
.action .container:after,
.action .container:before,
.bottom:after,
.bottom:before,
.hidden-menu .container:after,
.hidden-menu .container:before,
.input-wrapper:after,
.input-wrapper:before,
.list:after,
.list:before,
.numbers:after,
.numbers:before,
.text:after,
.text:before {
  display: table;
  content: "";
}
.header__content {
  height: 553px;
  width: 100%;
}
.info {
  background: rgba(0, 0, 0, 0.8);
  padding: 10px 20px 30px;
  width: 497px;
  height: 320px;
  margin-top: 35px;
}
.main .info__subtitle {
  span {
    color: #fff;
    font-size: 37px;
  }
}
.number1,
.number2 {
  width: 90px;
  float: left;
  margin-right: 50px;
}
.number1,
.number2,
.number3 {
  height: 72px;
  border: 2px solid #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
.info h1 {
  font-size: 62px;
  line-height: 64px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}
.info h2 {
  font-size: 37px;
  line-height: 38px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  margin-top: 11px;
  margin-bottom: 0;
}
.form-wrapper p,
.number1,
.number2,
.number3 {
  text-align: center;
}
.numbers {
  margin-top: 30px;
  margin-bottom: 5px;
}
.number1 {
  font-size: 68px;
  line-height: 70px;
}
.number2 {
  font-size: 65px;
  line-height: 68px;
}
.number3 {
  width: 128px;
  font-size: 68px;
  line-height: 70px;
  float: left;
}
.text1,
.text2,
.text3 {
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  color: #fff;
  font-family: "Open Sans";
  float: left;
}
.text {
  margin-bottom: 25px;
}
.text1 {
  margin-right: 60px;
  width: 85px;
}
.text2 {
  margin-right: 38px;
  width: 105px;
}
.text3 {
  width: 110px;
}
.info a {
  font-size: 21px;
  font-family: "Open Sans";
  text-transform: uppercase;
  background: #00c01b;
  padding: 8px 30px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #fff;
  margin-right: 15px;
}
.info a:hover {
  background: #fff;
  color: #00c01b;
}
.info span {
  color: #dad413;
  font-size: 22px;
}
.action {
  background: url(../img/bg_action1.png) top center;
  top: -130px;
  width: 100%;
  height: 490px;
  background-size: cover;
}
.form-wrapper {
  float: left;
  width: 494px;
  height: 473px;
  background: url(../img/dfdf.png);
  background-size: cover;
  bottom: 10px;
  padding: 130px 212px 160px 107px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.form-wrapper p {
  font-size: 18px;
  line-height: 20px;
  font-family: "Open Sans";
  margin: 10px 0 30px;
  font-weight: 300;
}
.three {
  width: 49px;
  float: left;
}
.form-wrapper input[type="phone"] {
  height: 40px;
  border: 1px solid #ccc;
  padding-left: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 167px;
}
.form-wrapper button {
  width: 168px;
  border: none;
  font-family: "Open Sans";
  text-transform: uppercase;
  padding: 11px 6px;
  font-size: 15px;
  line-height: 19px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  background: #00b419;
  margin-top: 35px;
  outline: 0;
}
.form-wrapper button:hover {
  border: 1px solid #00b419;
  padding: 10px 5px;
  background: 0 0;
  color: #00b419;
}
.atencion,
.timer h1 {
  color: #fff;
  font-family: "Open Sans";
}
.timer {
  float: left;
  width: 656px;
  text-align: left;
  padding-top: 135px;
}
.advantages,
.atencion,
.main {
  text-align: center;
}
.atencion {
  font-size: 16px;
  margin-top: 25px;
  margin-bottom: 10px;
  letter-spacing: 1px;
}
.timer h1,
.timer h2 {
  margin-bottom: 0;
  line-height: 1;
}
.timer h1 {
  font-size: 52px;
  font-weight: 700;
  letter-spacing: 4px;
  margin-top: 0;
}
.timer h2,
.timer h2 span {
  font-family: "Open Sans";
  font-size: 38px;
}
.timer h2 span {
  color: #f3eb04;
  font-weight: 400;
  margin-top: 12px;
}
.timer h2 {
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
}
.nav-list li a,
.phone-wrapper p {
  font-family: "Open Sans", sans-serif;
  color: #fff;
}
.hidden-menu {
  position: fixed;
  z-index: 1000;
  top: -100%;
  width: 100%;
  background: #333;
  border-bottom: 2px solid #fff;
}
.advantages,
.free,
.work {
  top: -130px;
  position: relative;
}
.hidden-menu .container {
  width: 1130px;
  margin: 0 auto;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.free__button,
.low-price__button {
  -webkit-box-sizing: border-box;
  outline: 0;
  text-transform: uppercase;
}
.logo-wrapperr {
  float: left;
  width: 55px;
  margin-top: 12px;
}
.logo-wrapperr img {
  width: 42px;
  height: 37px;
}
.nav-wrapper {
  float: left;
  width: 540px;
  margin-top: 21px;
}
.phone-wrapper {
  float: left;
  width: 330px;
  margin-top: 12px;
  margin-left: 16px;
}
.button-wrapper {
  float: left;
  width: 189px;
  margin-top: 16px;
}
.nav-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.nav-list li {
  display: inline-block;
}
.logo,
.logo img {
  display: block;
}
.nav-list li a {
  display: block;
  font-size: 16px;
  font-weight: 400;
}
.nav-list li a:hover {
  text-decoration: underline;
}
.phone-wrapper p {
  font-size: 34px;
  line-height: 36px;
  font-weight: 700;
  margin: -2px 0 0;
}
.advantages__title,
.button-wrapper a {
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}
.button-wrapper a {
  width: 100%;
  background: #00c01b;
  color: #fff;
  font-size: 16px;
  padding: 8px 23px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-decoration: none;
}
.button-wrapper a:hover {
  background: #fff;
  color: #00c01b;
}
/* .main {
  background: url(../img/bg_header.webp) top center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 728px;
  background-size: cover;
} */
.main .container {
  width: 990px;
  margin: 0 auto;
}
.action .container {
  width: 1150px;
  margin: 0 auto;
}
.logo-wrapper {
  width: 275px;
  float: left;
}
.logo img {
  max-width: 250px;
  height: 110px;
}
.advantages {
  background: url(../img/bg_advantages.webp) center bottom;
  background-size: cover;
  padding-top: 20px;
  padding-bottom: 35px;
}
.advantages .container {
  width: 990px;
  margin: 0 auto;
}
.advantages__title {
  text-align: center;
  font-size: 40px;
  color: #383836;
  line-height: 42px;
  margin: 0;
  letter-spacing: 1px;
}
.advantages__title span {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #212121;
  line-height: 42px;
}
.advantages__text,
.advantages__title-caption {
  color: #5a5a5a;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.advantages__title-caption {
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1px;
  margin-top: 12px;
  margin-bottom: 45px;
}
.advantages__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.advantages__list:after,
.advantages__list:before {
  content: "";
  display: table;
}
.advantages__item {
  width: 25%;
  float: left;
}
.advantages__text {
  line-height: 30px;
  margin: 0;
  font-size: 17px;
}
.advantages__text-bold,
.free__titleone {
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}
.advantages__picture {
  margin-bottom: 8px;
}
.free {
  background: #333;
  padding: 22px 0 29px;
}
.free .container {
  width: 990px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.content-wrapper,
.work__first-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-box-direction: normal;
}
.free__titleone {
  color: #fff;
  font-size: 46px;
  text-transform: uppercase;
  line-height: 48px;
  margin: 0;
}
.free__title {
  font-size: 34px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: #fff;
  margin: 16px 0 0;
  line-height: 36px;
}
.free__button {
  background: #fff;
  color: #333;
  font-size: 20px;
  font-family: "Open Sans";
  font-weight: 400;
  text-decoration: none;
  padding: 6px 12px 8px;
  letter-spacing: 0.6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  height: 47px;
  box-sizing: border-box;
  margin-top: 14px;
}
.work__title,
.work__title-caption {
  text-align: center;
  letter-spacing: 1px;
  font-family: "Open Sans", sans-serif;
}
.free__button:hover {
  color: #fff;
  background: #333;
  border: 2px solid #fff;
  padding: 4px 10px 6px;
}
.work {
  background: url(../img/bg_house.webp) center center fixed;
  background-size: cover;
  padding-top: 29px;
  padding-bottom: 26px;
}
.work .container {
  width: 1200px;
  margin: 0 auto;
}
.work__title {
  font-weight: 400;
  font-size: 40px;
  color: #fff;
  line-height: 42px;
  margin: 0;
}
.work__title span {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  line-height: 42px;
}
.work__title-caption {
  color: #929292;
  font-weight: 300;
  font-size: 18px;
  line-height: 19px;
  margin: 12px 0 0;
}
.content-wrapper {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 53px;
}
.work__first-list {
  width: 50%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: relative;
}
.work__first-list .work__list-item,
.work__second-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}
.work__first-list .work__list-item {
  padding-right: 27px;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.work__first-list .work__list-item:nth-child(1):hover .project {
  background: url(../img/icon11.png);
}
.work__first-list .work__list-item:nth-child(2):hover .stroy {
  background: url(../img/icon33.png);
}
.work__first-list .work__list-item:nth-child(3):hover .inzhen {
  background: url(../img/icon55.png);
}
.work__first-list .work__list-item:hover .work__item-title,
.work__first-list .work__list-item:hover .work__item-title-description {
  color: #939393;
}
.work__first-list .work__list-item:hover .work__item-description {
  color: #fff;
}
.work__first-list .project {
  background: url(../img/icon1.png);
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  margin-left: 32px;
  width: 90px;
  height: 90px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.work__first-list .inzhen,
.work__first-list .stroy {
  -webkit-box-ordinal-group: 2;
  margin-left: 32px;
  height: 90px;
  -webkit-transition: all 0.3s ease;
}
.work__first-list .stroy {
  background: url(../img/icon3.png);
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  width: 90px;
  transition: all 0.3s ease;
}
.work__first-list .inzhen {
  background: url(../img/icon5.png);
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  width: 155px;
  transition: all 0.3s ease;
}
.contacts__form,
.work__second-list {
  -webkit-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}
.work__second-list .diz,
.work__second-list .sogl {
  -webkit-transition: all 0.3s ease;
  height: 90px;
  margin-right: 32px;
}
.work__first-list:after {
  background: #929292;
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 320px;
  left: 600px;
  top: 11px;
}
.low-price,
.portfolio,
.price,
.sale {
  top: -130px;
  position: relative;
}
.work__second-list {
  width: 50%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.low-price .container,
.work__second-list .work__list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}
.work__second-list .work__list-item {
  padding-left: 32px;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.work__second-list .work__list-item:nth-child(1):hover .sogl {
  background: url(../img/icon22.png);
}
.work__second-list .work__list-item:nth-child(2):hover .diz {
  background: url(../img/icon44.png);
}
.work__second-list .work__list-item:nth-child(3):hover .rasch {
  background: url(../img/icon66.png);
}
.work__second-list .work__list-item:hover .work__item-title,
.work__second-list .work__list-item:hover .work__item-title-description {
  color: #939393;
}
.work__second-list .work__list-item:hover .work__item-description {
  color: #fff;
}
.work__second-list .sogl {
  background: url(../img/icon2.png);
  width: 90px;
  transition: all 0.3s ease;
}
.work__second-list .diz {
  background: url(../img/icon4.png);
  width: 161px;
  transition: all 0.3s ease;
}
.work__second-list .rasch {
  background: url(../img/icon6.png);
  margin-right: 32px;
  width: 166px;
  height: 90px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.work__second-list .work__item-text {
  text-align: left;
}
.work__list-item {
  margin-bottom: 35px;
}
.work__item-text {
  text-align: right;
}
.work__item-title {
  margin: 0;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.7px;
  line-height: 26px;
}
.work__item-description,
.work__item-title-description {
  font-weight: 400;
  line-height: 16px;
  font-family: "Open Sans", sans-serif;
}
.work__item-title-description {
  margin: 0;
  color: #fff;
  font-size: 16px;
}
.work__item-description {
  color: #939393;
  margin: 6px 0 0;
  font-size: 14px;
}
.low-price {
  background: #c00;
  padding-top: 27px;
  padding-bottom: 31px;
}
.low-price .container {
  width: 990px;
  margin: 0 auto;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.price__list,
.sale .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}
.low-price__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 50px;
  line-height: 52px;
  color: #fff;
  margin: 0;
}
.low-price__button {
  background: #fff;
  color: red;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-decoration: none;
  padding: 6px 22px 8px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  height: 47px;
  box-sizing: border-box;
}
.low-price__title-description,
.price__title {
  font-size: 42px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 44px;
}
.low-price__button:hover {
  background: #c00;
  color: #fff;
  border: 2px solid #fff;
  padding: 4px 20px 6px;
}
.low-price__title-description {
  color: #fff;
  margin: 6px 0 0;
}
.price {
  padding-bottom: 15px;
  padding-top: 30px;
  background: url(../img/bg_action.webp) center center;
  background-size: cover;
}
.price .container {
  width: 990px;
  margin: 0 auto;
}
.price__title {
  color: #000;
  text-align: center;
  margin: 0;
}
.item__marker,
.item__marker a {
  color: #fff;
  font-family: "Open Sans", sans-serif;
}
.price__title span {
  font-weight: 700;
}
.price__title-sescription {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  margin-top: 6px;
  margin-bottom: 0;
}
.price__title-sescription span {
  font-weight: 700;
}
.price__list {
  padding: 0;
  margin-top: 11px;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  list-style: none;
}
.price__item {
  width: 300px;
}
.shadow {
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #fff;
}
.item__marker {
  background: #c00;
  -webkit-border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  height: 30px;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}
.item__marker a {
  font-size: 13px;
  text-transform: lowercase;
}
.item__marker_white {
  background: 0 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.item__title-wrapper {
  height: 70px;
  text-align: center;
  padding-top: 18px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.item__title-wrapper .item__title_marg {
  margin-top: 9px !important;
}
.item__title-wrapper .item__title {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 23px;
  color: #282828;
  letter-spacing: 0.3px;
}
.item__title-wrapper .item__title-description {
  margin: 4px 0 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  letter-spacing: 0.3px;
}
.bel,
.cena {
  margin: 0;
  text-align: center;
}
.cena {
  color: #c00;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 43px;
}
.general,
.metri {
  line-height: 29px;
}
.cena span {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 54px;
}
.cena_black {
  color: #212121;
}
.bel {
  color: #909090;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
}
.desc,
.general,
.metri {
  color: #212121;
  font-family: "Open Sans", sans-serif;
}
.grey {
  background: #eee;
  padding-bottom: 15px;
}
.grey_radius {
  -webkit-border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-right-radius: 5px;
}
.desc {
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 8px;
}
.metri {
  font-weight: 300;
  font-size: 22px;
  margin: 0 0 6px;
}
.metri span.c {
  font-weight: 600;
  font-size: 29px;
}
.metri span.m {
  font-size: 15px;
}
.wrapper-metri {
  width: 214px;
  margin: 0 auto;
  text-align: right;
}
.general_tt .gen {
  text-transform: lowercase !important;
}
.general {
  font-weight: 600;
  font-size: 29px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}
.general .rub {
  font-size: 15px;
  font-weight: 300;
}
.general .gen {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
}
.item__foot-btn,
.sale__title {
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}
.item__foot {
  background: #eee;
  text-align: center;
  height: 65px;
  padding-top: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 9px;
}
.contacts__map,
.sale__button {
  -webkit-box-sizing: border-box;
}
.item__foot-btn {
  background: #c00;
  color: #fff;
  text-decoration: none;
  padding: 10px 21px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  outline: 0;
}
.questions__button,
.sale__button {
  -webkit-border-radius: 6px;
  text-decoration: none;
  outline: 0;
  text-transform: uppercase;
}
.item__foot-btn:hover {
  background: #929292;
  color: #f7f7f7;
}
.sale {
  background: #c00;
  padding-top: 19px;
  padding-bottom: 29px;
}
.sale .container {
  width: 990px;
  margin: 0 auto;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.portfolio__item-top,
.portfolio__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}
.sale__title {
  margin: 0;
  color: #fff;
  font-size: 42px;
  line-height: 52px;
}
.sale__title-bold {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 50px;
  line-height: 52px;
}
.portfolio__title,
.sale__button {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.sale__button {
  background: #fff;
  color: red;
  font-size: 20px;
  padding: 6px 20px 8px;
  border-radius: 6px;
  height: 47px;
  box-sizing: border-box;
  margin-top: 10px;
  letter-spacing: 1px;
}
.sale__button:hover {
  background: #c00;
  color: #fff;
  border: 2px solid #fff;
  padding: 4px 18px 6px;
}
.portfolio {
  background: url(../img/bg_portf.webp) center center fixed;
  background-size: cover;
  padding: 28px 0 0;
}
.portfolio .container {
  width: 990px;
  margin: 0 auto;
}
.portfolio__title {
  font-size: 42px;
  line-height: 44px;
  color: #fff;
  margin: 0;
  text-align: center;
}
.portfolio__title-caption {
  color: #929292;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 19px;
  margin: 12px 0 0;
  letter-spacing: 1px;
}
.portfolio__button,
.portfolio__item-title {
  color: #fff;
  letter-spacing: 1px;
  font-family: "Open Sans", sans-serif;
}
.portfolio__title-bold {
  font-weight: 700;
}
.portfolio__list {
  padding: 0;
  margin: 38px 0 0;
  list-style: none;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
.portfolio__list:after,
.portfolio__list:before {
  background: #929292;
  position: absolute;
  width: 1px;
  height: 244px;
  left: 495px;
  content: "";
  display: block;
}
.portfolio__list:after {
  top: 36px;
}
.portfolio__list:before {
  top: 414px;
}
.portfolio__item {
  width: 430px;
  margin-bottom: 44px;
}
.portfolio__link {
  display: block;
  position: relative;
}
.portfolio__link:before {
  content: "";
  opacity: 0;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(../img/zoom.png) center center no-repeat, rgba(0, 0, 0, 0.7);
}
#toTop:hover,
.portfolio__link:hover:before {
  opacity: 1;
}
.contacts__title:after,
.footer__title:after {
  content: "";
  left: 74px;
}
.portfolio__image {
  display: block;
  width: 100%;
  height: auto;
}
.portfolio__item-title {
  font-size: 23px;
  line-height: 25px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 400;
}
.portfolio__item-top {
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 12px;
}
.portfolio__form,
.recall__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}
.portfolio__button {
  font-weight: 300;
  font-size: 13px;
  font-style: italic;
  position: relative;
  top: 4px;
  outline: 0;
}
.advantages-two,
.contacts,
.questions,
.recall {
  top: -130px;
  position: relative;
}
.portfolio__button:hover {
  color: #00b419;
}
.portfolio__form {
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 16px;
}
.portfolio__input {
  width: 188px;
  height: 35px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: none;
  letter-spacing: 0.2px;
  font-size: 14px;
  font-style: italic;
  outline: 0;
  padding-left: 10px;
}
.portfolio__input:focus::-webkit-input-placeholder {
  color: transparent;
}
.portfolio__input:focus::-moz-placeholder {
  color: transparent;
}
.portfolio__input:focus:-moz-placeholder {
  color: transparent;
}
.portfolio__input:focus:-ms-input-placeholder {
  color: transparent;
}
.portfolio__form-button {
  width: 198px;
  height: 37px;
  background: #00b419;
  color: #fff;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  outline: 0;
}
.portfolio__form-button:hover {
  background: #fff;
  color: #00b419;
}
.recall {
  background: url(../img/bg_recall.jpg) center center;
  background-size: cover;
  padding: 27px 0 60px;
}
.recall .container {
  width: 990px;
  margin: 0 auto;
}
.recall__title {
  font-size: 42px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 44px;
  color: #212121;
  text-align: center;
  margin: 0;
}
.recall__title span {
  font-weight: 700;
}
.recall__title-sescription {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  margin-top: 6px;
  margin-bottom: 0;
  color: #5a5a5a;
}
.recall__name,
.recall__title-sescription span {
  font-weight: 700;
}
.recall__list {
  list-style: none;
  padding: 0;
  margin: 26px 0 0;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.advanatges-two__list,
.map-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}
.recall__item {
  text-align: center;
  margin-right: 46px;
  width: 300px;
}
.recall__item:last-child {
  margin-right: 0;
}
.recall__item:first-child .recall__text-wrapper {
  padding-bottom: 20px;
}
.recall__name {
  text-transform: uppercase;
  color: #fff;
  text-align: right;
  margin: 10px 0 0;
}
.questions__title,
.questions__title_large,
.recall__text {
  font-weight: 300;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}
.recall__text-wrapper {
  background: rgba(0, 0, 0, 0.7);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-top: 12px;
  padding: 30px 25px 20px;
}
.recall__text {
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}
.questions {
  background: url(../img/bg_questions.jpg) center center fixed;
  background-size: cover;
  padding: 131px 0 129px;
}
.questions .container {
  width: 990px;
  margin: 0 auto;
}
.questions__title {
  font-size: 34px;
  line-height: 36px;
  margin: 0;
}
.questions__title_large {
  font-size: 48px;
  line-height: 50px;
  margin: 1px 0 41px;
  letter-spacing: 1.4px;
}
.questions__title_bold {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 74px;
  line-height: 76px;
  margin: 0;
  letter-spacing: 2.2px;
}
.questions__button {
  color: #333;
  background: #fff;
  padding: 9px 36px 8px;
  font-size: 21px;
  border-radius: 6px;
  margin-left: 22px;
}
.questions__button:hover {
  background: #c00;
  color: #fff;
}
.map-content {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}
.map-text {
  font-size: 18px;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 24px;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 10px;
}
.advantages-two__description,
.advantages-two__title {
  font-family: "Open Sans", sans-serif;
}
.advantages-two {
  background: #333;
  padding: 30px 0 40px;
}
.advantages-two .container {
  width: 990px;
  margin: 0 auto;
}
.advanatges-two__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.advantages-two__item,
.contacts .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}
.advantages-two__item {
  width: 480px;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 52px;
}
.advantages-two__item:nth-child(3),
.advantages-two__item:nth-child(4) {
  margin-bottom: 0;
}
.advantages-two__item .advantages-two__picture:nth-child(2) {
  display: none;
}
.advantages-two__item:hover .advantages-two__picture:nth-child(2) {
  display: block;
}
.advantages-two__item:hover .advantages-two__picture:nth-child(1) {
  display: none;
}
.advantages-two__item:hover .advantages-two__title {
  color: #939393;
}
.advantages-two__item:hover .advantages-two__description {
  color: #fff;
}
.advanatges-two__info {
  margin-left: 25px;
}
.advantages-two__title {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
}
.advantages-two__description {
  margin: 3px 0 0;
  color: #939393;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.contacts__republic,
.contacts__title {
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.4px;
  font-family: "Open Sans", sans-serif;
}
.contacts {
  background: url(../img/bg_contacts.webp) center center;
  background-size: cover;
  padding: 73px 0 64px;
}
.contacts .container {
  width: 990px;
  margin: 0 auto;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.contacts__form {
  background: rgba(0, 0, 0, 0.7);
  width: 360px;
  height: 340px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.contacts__title {
  font-size: 33px;
  line-height: 34px;
  margin: 0;
  position: relative;
}
.contacts__title:after {
  position: absolute;
  background: #06ff00;
  width: 100px;
  height: 1px;
  top: 47px;
}
.contacts__republic {
  font-size: 19px;
  line-height: 22px;
  margin: 25px 0 0;
}
.contacts__number,
.contacts__number a {
  font-size: 20px;
  letter-spacing: 0.1px;
  color: #929292;
  line-height: 28px;
  margin-top: 4px;
  text-align: center;
}
.contacts__number a {
  text-decoration: none;
  border-bottom: 1px solid #929292;
}
.contacts__button {
  display: block;
  width: 270px;
  height: 36px;
  background: #00c01b;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  margin-top: 19px;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  padding-top: 8px;
  letter-spacing: 0.8px;
  outline: 0;
}
.footer__form,
.footer__top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}
.contacts__button:hover {
  background: #fff;
  color: #00c01b;
}
.footer__form-title,
.footer__title {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 28px;
  line-height: 30px;
}
.contacts__map {
  width: 490px;
  height: 413px;
  background: rgba(146, 146, 146, 0.7);
  padding: 10px;
  box-sizing: border-box;
  background-image: url(../img/bg_map.webp);
  background-position: center;
  background-size: contain;
  position: relative;
}
.contacts__map-note {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.mymap {
  width: 470px;
  height: 393px;
}
.footer {
  background: url(../img/bg_footer.webp) center center;
  background-size: cover;
  position: relative;
  top: -130px;
  padding: 30px 0 17px;
}
.footer .container {
  width: 990px;
  margin: 0 auto;
}
.footer__top-left {
  padding-left: 55px;
  width: 50%;
}
.footer__title {
  text-transform: uppercase;
  margin: 21px 0 15px;
  position: relative;
}
.footer__title:after {
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 100px;
  height: 1px;
  top: 47px;
}
.footer__top {
  display: flex;
}
.footer__top-right {
  width: 50%;
  padding-left: 195px;
  background: url(../img/sms.png) 73px 51px no-repeat;
}
.footer__form-title {
  margin: 0;
}
.footer__form-input,
.footer__form-textarea {
  background: #fff;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-style: italic;
  outline: 0;
}
.footer__form {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 21px;
}
.footer__bottom,
.thanks-popup {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}
.footer__form-input {
  width: 184px;
  height: 31px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding-left: 13px;
  border: none;
  margin-bottom: 18px;
}
.footer__form-input:focus::-webkit-input-placeholder {
  color: transparent;
}
.footer__form-input:focus::-moz-placeholder {
  color: transparent;
}
.footer__form-input:focus:-moz-placeholder {
  color: transparent;
}
.footer__form-input:focus:-ms-input-placeholder {
  color: transparent;
}
.footer__form-textarea {
  width: 355px;
  height: 85px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding: 5px 0 0 13px;
  border: none;
  margin-bottom: 22px;
  resize: none;
}
.footer__form-textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
.footer__form-textarea:focus::-moz-placeholder {
  color: transparent;
}
.footer__form-textarea:focus:-moz-placeholder {
  color: transparent;
}
.footer__form-textarea:focus:-ms-input-placeholder {
  color: transparent;
}
.footer__form-button {
  border: none;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  width: 198px;
  height: 37px;
  background: #929292;
  font-size: 17px;
  color: #fff;
  cursor: pointer;
  outline: 0;
}
.footer__form-button:hover {
  color: #fff;
  background: #c00;
}
.footer__house-project {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin: 28px 0 7px;
  font-size: 17px;
  line-height: 20px;
}
.footer__description {
  color: #929292;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin: 0;
  font-size: 14px;
  line-height: 23px;
}
.footer__collaboration,
.footer__copyright {
  color: #494848;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
  width: 50%;
  font-weight: 400;
}
.footer__bottom {
  border-top: 1px solid rgba(146, 146, 146, 0.3);
  display: flex;
  padding: 13px 0 0;
  margin-top: 30px;
}
#toTop,
.hidden {
  display: none;
}
.footer__copyright {
  padding-left: 55px;
}
.footer__collaboration {
  text-align: right;
}
#toTop {
  width: 90px;
  height: 90px;
  background: url(../img/top.png);
  position: fixed;
  bottom: 40px;
  left: 30px;
  cursor: pointer;
  opacity: 0.4;
}
.mfp-content .call {
  padding: 30px 20px 20px;
  width: 440px;
  margin: auto;
  background: #fff;
  border: none;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  position: relative;
}
.mfp-content .call h4 {
  color: #000;
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  margin: 0 35px 30px;
  font-weight: 400;
  text-transform: uppercase;
}
.mfp-content .call .call__description,
.mfp-content .call .call__input,
.mfp-content .call .call__label {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}
.mfp-content .call .call__label {
  margin: 0;
  text-align: center;
  font-size: 16px;
  position: relative;
}
.mfp-content .call .call__label:after {
  position: absolute;
  content: "";
  width: 480px;
  height: 1px;
  background: #ccc;
  top: -120px;
  left: -20px;
}
.mfp-content .call .call__description {
  font-size: 18px;
  text-align: center;
  margin-bottom: 45px;
  margin-top: 30px;
}
.mfp-content .call .call__input {
  width: 270px;
  height: 40px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font-size: 14px;
  font-style: italic;
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 5px 25px;
  color: #000;
  margin: 0 85px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.mfp-content .call .call__input:focus {
  outline: 0;
}
.mfp-content .call .call__input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-style: italic;
  color: #9999a4;
  line-height: 17px;
}
.mfp-content .call .call__input:-moz-placeholder {
  font-family: Conv_Lato-Regular;
  color: #9999a4;
  line-height: 17px;
}
.mfp-content .call .call__input::-moz-placeholder {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-style: italic;
  color: #9999a4;
  line-height: 17px;
}
.mfp-content .call .call__input:-ms-input-placeholder {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-style: italic;
  color: #9999a4;
  line-height: 17px;
}
.mfp-content .call .call__button {
  background: #00c01b;
  color: #fff;
  width: 270px;
  height: 44px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 21px;
  font-family: "Open Sans", sans-serif;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  left: 50%;
  margin-left: -135px;
  outline: 0;
  border: none;
}
.mfp-content .call .call__button:hover {
  background: #fff;
  border: 2px solid #00c01b;
  color: #00c01b;
}
.mfp-content .call .mfp-close {
  color: #333;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #333;
  right: 10px;
  top: 10px;
}
.thanks-popup {
  background: #fff;
  position: relative;
  padding: 26px;
  max-width: 600px;
  margin: auto;
  display: flex;
}
.thanks-popup p {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.125em;
  margin: auto;
}
@media only screen and (min-width: 1700px) {
  .action {
    background: url(../img/bg_action11.png);
    background-size: cover;
  }
}

.header__content {
  position: relative;
}
.info-action {
  display: inline-block;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 50px;
  text-align: left;
}

.info-action__small {
  text-align: right;
  font-size: 12px;
}
.info-action__title {
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 48px;
}
.info-action__caption {
  font-size: 20px;
}
.info-action__big {
  color: #c00;
  font-size: 70px;
}

.rings-counter {
  margin-top: 45px;
  padding-left: 35px;

  .shadow {
    background: initial;
  }

  .flip-clock-divider .flip-clock-label {
    font-size: 14px;
    color: #fff;
  }
}
