@import '_mixins';

.price__title-sescription br {
  display: none;
}

/* Tablet */
@include media(tablet) {
  .price {
    padding: 25px 0 !important;

    .container {
      width: auto;
    }
  }
  .price__title {
    padding: 0 10px;
    font-size: 40px;
    line-height: 36px;
  }
  .price__title-sescription {
    margin: 0;
    padding: 15px 10px;
    font-size: 12px;

    br {
      display: inline;
    }
  }
  .price_two {
    .price__title {
      padding: 10px 10px 0px 10px;
      font-size: 34px;
      line-height: 42px;
    }
    .price__title-sescription {
      display: none;
    }
  }
  .price__list {
    margin: 0 !important;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
  }
}

/* Mobile */
@include media(mobile) {

}
