.work-popup {
  background-color: #fff;
  border-radius: 5px;
  padding: 25px 10px;

  .mfp-close {
    border: 1px solid #333;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-top: 10px;
    width: 30px;
    height: 30px;
  }

  .work-popup__footer .call {
    width: 100%;
    padding: 0;

    .call__control {
      padding-bottom: 20px;
    }

    .call__button {
      font-size: 16px;
    }
  }
}
.work-popup__header {
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 20px;
}
.work-popup__body {
  padding-top: 20px;
  text-align: center;

  ul {
    margin: 0 10px;
  }

  li {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
  }
}
.work-popup__footer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.work-popup__btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  background: #00c01b;
  padding: 4px 24px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  outline: 0;
  color: #fff;
  text-decoration: none;
}
