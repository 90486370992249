@import '_variables';

@mixin clear-pseudo {
  &::before, &::after,::before, ::after { content: none !important; }
}

@mixin not-last-child {
  &:not(:only-child):not(:last-child) {
    @content;
  }
}

@mixin media($type) {
  .site-mobile {
    @if $type == tablet {
      @media only screen and (max-width: $screen-sm-max) {
        @content;
      }
    }

    @else if $type == mobile {
      @media only screen and (max-width: $screen-xs) {
        @content;
      }
    }
  }
}