@import '_mixins';

.portfolio__item-more {
  display: none;
}

/* Tablet */
@include media(tablet) {
  .portfolio {
    padding: 25px 10px;
    @include clear-pseudo();

    .container {
      width: auto;
    }
  }
  .portfolio__title {
    font-size: 40px;
    line-height: 40px;
    padding-bottom: 12px;
  }
  .portfolio__title-caption {
    display: none;
  }
  .portfolio__list {
    justify-content: center;
    margin-top: 30px;
  }
  .portfolio__item {
    width: auto;
    margin: 0;

    @include not-last-child() {
      padding-bottom: 30px;
    }
  }
  .portfolio__item-top {
    align-items: center;
  }
  .portfolio__button {
    display: none;
  }
  .portfolio__item-title {
    font-size: 18px;
  }
  .portfolio__item-more {
    display: inline-block;
    color: #fff;
    font-size: 12px;
    text-decoration: underline;
    font-style: italic;
    cursor: pointer;
    font-family: 'Open Sans',sans-serif;
    font-weight: 300;
  }
  .portfolio__form {
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
  .portfolio__input {
    margin-right: 10px;
    width: auto;
    flex: 2;
  }
  .portfolio__form-button {
    width: auto;
    flex: 1;

    span {
      display: none;
    }
  }
}

/* Mobile */
@include media(mobile) {
  .portfolio__item-title {
    padding-right: 10px;
  }
}
