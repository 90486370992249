@import '_mixins';

/* Tablet */
@include media(tablet) {
  .questions {
    @include clear-pseudo();
    padding: 20px 10px;
    text-align: center;

    .container {
      width: auto;
    }
  }
  .questions__title {
    font-size: 20px;
  }
  .questions__title_large {
    font-size: 24px;
    line-height: 30px;
    margin: 0;
    padding-bottom: 20px;
  }
  .questions__button {
    margin: 0;
    font-size: 18px;
    padding: 5px 10px;
  }
}

/* Mobile */
@include media(mobile) {

}
