@import 'mobile-menu';
@import '_mixins';


body {
  background: #333;
  min-width: 1200px;
}

#site {
  display: block;
  height: 100%;
}

.logo-wrapper .logo-mobile {
  display: none;
}

.header-menu__phone_mobile,
.header-menu__phone_btn,
.mobile-menu__btn_open {
  display: none;
}

@import 'common';
@import 'fixed-header';
@import 'section-main';
@import 'section-action';
@import 'section-advantages';
@import 'section-free';
@import 'section-work';
@import 'section-low-price';
@import 'section-price';
@import 'section-sale';
@import 'section-portfolio';
@import 'section-questions';
@import 'section-advantages-two';
@import 'section-contacts';
@import 'section-footer';
@import 'to-top-button';
@import 'contact-form-popup';
@import 'to-top-button';
@import 'popup-work';
