@import '_mixins';

/* Tablet */
@include media(tablet) {
  .work {
    padding: 20px 10px;

    .container {
      width: auto;
    }
    .content-wrapper {
      display: none;
    }
    .content-wrapper_mobile {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      padding: 0 5px;
    }
  }
  .work__title {
    font-size: 40px;
    padding-bottom: 12px;
  }
  .work__title-caption {
    display: none;
  }
  .work__list-row {
    padding: 5px 10px;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    .work__list-item {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0;

      &:not(:only-child):not(:last-child) {
        padding-right: 10px;
      }

      .work__item-icon {
        width: 70px;
        height: 70px;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        padding-bottom: 10px;
        background-repeat: no-repeat;
        background-size: contain;

        &.project {
          background-image: url('../img/work-icon-1.png');
        }
        &.sogl {
          background-image: url('../img/work-icon-4.png');
        }
        &.stroy {
          background-image: url('../img/work-icon-3.png');
        }
        &.diz {
          background-image: url('../img/work-icon-2.png');
        }
        &.inzhen {
          background-image: url('../img/work-icon-5.png');
        }
        &.rasch {
          background-image: url('../img/work-icon-6.png');
        }
      }
      .work__item-text {
        text-align: center;
      }
      .work__item-title {
        font-size: 14px;
        line-height: 16px;
      }
      .work__item-title-description {
        font-size: 10px;
      }
      .work__item-description {
        display: none;
      }
    }
  }
}

/* Mobile */
@include media(mobile) {

}
