@import '_mixins';

/* Tablet */
@include media(tablet) {
  .action {
    height: auto;
    background: none;
    background-position-x: 55%;
    background-color: #000;

    &::before,
    &::after {
      display: block;
      content: '';
      width: 100%;
    }

    &::before {
      background-image: url('../img/action-before.webp');
      height: 90px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: -50px;
      background-position-x: left;
    }

    &::after {
      background-image: url('../img/action-after.webp');
      height: 30px;
      position: absolute;
      bottom: 0;
    }

    .container {
      width: auto;
      padding-top: 25px;
    }
  }
  .form-wrapper {
    background: none;
    width: auto;
    height: auto;
    float: none;
    padding: 0;
    position: static;
  }
  .timer {
    float: none;
    width: auto;
    padding: 0 10px;
  }
  .promo {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
  }
  .promo__label {
    padding-bottom: 10px;
    font-size: 60px;
  }
  .promo__price {
    font-size: 100px !important;
    text-align: center;
  }
  .promo-extend {
    font-size: 26px !important;
    text-align: center;
  }
  .promo-note {
    font-size: 26px !important;
    padding-top: 10px;
  }
  #free {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 30px 50px;

    p {
      color: #fff;
      margin: 0;
      padding-bottom: 10px;
      
      br {
        display: none;
      }
    }
    input[type="phone"] {
      margin-bottom: 10px;
      width: 100%;
    }

    button {
      width: auto;
      margin: 0;
      padding: 11px 12px;
    }
  }
}

/* Mobile */
@include media(mobile) {
  .promo {
    flex-direction: column;
    justify-content: normal;
    align-items: center;
  }

  #free {
    button {
      width: 100%;
    }
  }
}
