@import '_mixins';

/* Tablet */
@include media(tablet) {
  .contacts {
    @include clear-pseudo();
    padding: 20px 10px;

    .container {
      width: auto;
    }
  }
  .contacts__form {
    width: auto;
    height: auto;
    padding: 20px;
  }
  .contacts__republic {
    margin-top: 15px !important;
    font-size: 18px;
  }
  .contacts__button {
    width: auto;
    height: auto;
    padding: 4px 8px;
    margin-top: 10px;
  }
  .contacts__number {
    font-size: 14px !important;
    margin-top: 0;
  }
  .contacts__map {
    display: none;
  }
}

/* Mobile */
@include media(mobile) {

}
