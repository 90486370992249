@import '_mixins';

.sale__title_mobile,
.sale__subtitle_mobile {
  display: none;
}

/* Tablet */
@include media(tablet) {
  .sale {
    padding: 25px 5px;

    .container {
      width: auto;
      display: flex;
      justify-content: center;
    }
  }
  .sale__title_desctop,
  .sale__subtitle_desctop {
    display: none;
  }
  .sale__title_mobile,
  .sale__subtitle_mobile {
    display: block;
  }
  .sale__title_mobile {
    margin: 0;
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 8px;
  }
  .sale__subtitle_mobile {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 4px;
  }
}

/* Mobile */
@include media(mobile) {

}
