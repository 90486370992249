@import '_mixins';

.anchor {
  position: relative;
  top: -60px;

  &.anchor_cont {
    display: block;
    position: relative;
    top: -250px;
    visibility: hidden;
  }
}

.content-wrapper_mobile,
.advantages__title_mobile,
.mobile_divider,
.rings-counter_mobile {
  display: none;
}

/* Tablet */
@include media(tablet) {
  /* Hide elements */
  .header-menu .bottom,
  .hidden-menu .nav-wrapper,
  .recall,
  .questions,
  .rings-counter {
    display: none;
  }

  .mobile_divider {
    display: block;
    width: 40%;
    height: 1px;
    border-top: 1px solid #fff;
    margin: 0 auto;
  }

  .rings-counter_mobile {
    display: block;
    padding-top: 20px;

    canvas {
      display: block;
      margin: 0 auto;
    }
  }

  body {
    min-width: auto;
  }
}

/* Mobile */
@include media(mobile) {

}