@import '_mixins';

/* Tablet */
@include media(tablet) {
  .advantages {
    @include clear-pseudo();

    .container {
      width: auto;
    }
  }
  .advantages__title {
    padding-bottom: 20px;
  }
  .advantages__title-caption {
    display: none;
  }
  .advantages__list {
    display: flex;
    flex-wrap: wrap;
  }
  .advantages__row {
    flex: 1;
    display: flex;
    padding: 0 10px;
    min-width: 70%;
    box-sizing: border-box;

    &:not(:only-child):not(:last-child) {
      padding-bottom: 10px;
    }
  }
  .advantages__item {
    flex: 1;
    width: auto;
    float: none;
    position: relative;

    &:not(:only-child):not(:last-child) {
      padding-right: 10px;
    }

    img {
      width: 70%;
    }
  }
  .advantages__text {
    font-size: 13px;
    line-height: 16px;
  }
  .advantages__text_mobile-hide {
    display: none;
  }
}

/* Mobile */
@include media(mobile) {

}
