@import '_mixins';

/* Tablet */
@include media(tablet) {
  .fixed-header {
    ::before, ::after {
      content: none !important;
    }

    .nav-wrapper,
    .phone-wrapper,
    .button-wrapper {
      display: none;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
      width: auto;
      line-height: initial;
    }

    .logo-wrapperr {
      margin: 0;
      float: none;
    }

    .header-menu__phone_btn {
      display: block;
      font-size: 14px;
      font-weight: 400;
      background: #00c01b;
      padding: 6px 12px;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      outline: 0;
      font-family: 'Open Sans',sans-serif;
      color: #fff;
      text-decoration: none;

      &:hover {
        background: #fff;
        color: #00c01b;
      }
    }
  }
}

/* Mobile */
@include media(mobile) {
  .fixed-header {
    .header-menu__phone_btn {
      display: none;
    }
  }
}