@import '_mixins';

/* Tablet */
@include media(tablet) {
  #toTop {
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    left: unset;
    right: 30px;
    bottom: 30px;
    z-index: 9999;
  }
}

/* Mobile */
@include media(mobile) {

}