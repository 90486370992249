@import '_mixins';

/* Tablet */
@include media(tablet) {
  .free {
    padding: 25px 5px;

    .container {
      width: auto;
      display: flex;
      justify-content: center;
    }
  }
  .free__titleone {
    padding-bottom: 10px;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
  }
  .free__title {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-basis: 100%;
    justify-content: center;
    padding-bottom: 10px;
  }
  .free__button {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-top: 0;
  }
}

/* Mobile */
@include media(mobile) {
  .free__title {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    padding-bottom: 0;
  }
  .free__titleone {
    padding-bottom: 10px;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
  }
  .free__button {
    margin-top: 14px;
  }
}
