@import '_mixins';

.footer__collaboration_mobile,
.footer__copyright_mobile {
  display: none;
}

/* Tablet */
@include media(tablet) {
  .footer {
    @include clear-pseudo();
    padding: 20px 10px;

    .container {
      width: auto;
    }
  }
  .footer__top {
    flex-direction: column-reverse;
  }
  .footer__top-right {
    background-image: url(../img/sms.png);
    background-position: right top;
    background-size: 70px;
    padding-left: 0;
    width: 100%;
    padding-bottom: 20px;
  }
  .footer__form-title {
    font-size: 24px;
  }
  .footer__form-textarea {
    width: 90%;
  }
  .footer__form-button {
    width: 100%;
  }
  .footer__top-left {
    width: 100%;
    padding: 0;
    text-align: center;
  }

  .footer__copyright_desctop {
    display: none;
  }
  .footer__copyright_mobile {
    display: block;
    padding: 0;
    padding-right: 10px;
    font-size: 10px;
  }

  .footer__collaboration_desctop {
    display: none;
  }
  .footer__collaboration_mobile {
    font-size: 10px;
    display: block;
  }
}

/* Mobile */
@include media(mobile) {

}
